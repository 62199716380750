var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CCard",
        { staticClass: "mt-4" },
        [
          _c("CCardBody", { staticClass: "p-3" }, [
            _c("h5", { staticClass: "mb-3" }, [
              _vm._v("Delivery Distance Configuration"),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-4 mb-3" },
                [
                  _c("label", { staticClass: "d-block mb-2" }, [
                    _vm._v("Enable Fee"),
                  ]),
                  _c("CSwitch", {
                    attrs: {
                      color: "success",
                      checked: _vm.delivery_distances.fee_enabled,
                      labelOn: "Yes",
                      labelOff: "No",
                    },
                    on: {
                      "update:checked": [
                        function ($event) {
                          return _vm.$set(
                            _vm.delivery_distances,
                            "fee_enabled",
                            $event
                          )
                        },
                        (val) => {
                          if (!val) {
                            _vm.delivery_distances.based_fee = 0
                            _vm.delivery_distances.threshold_meter = 0
                          } else {
                            _vm.delivery_distances.threshold_meter = 1000
                          }
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-4 mb-3" },
                [
                  _c("label", [_vm._v("Base Delivery Fee")]),
                  _c("CInput", {
                    attrs: {
                      type: "number",
                      disabled: !_vm.delivery_distances.fee_enabled,
                      value: _vm.delivery_distances.based_fee,
                      min: "0",
                      max: "50",
                      step: "0.5",
                      placeholder: "Base fee",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.delivery_distances,
                          "based_fee",
                          $event
                        )
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-content",
                        fn: function () {
                          return [_c("CIcon", { attrs: { name: "cil-euro" } })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4 mb-3" }, [
                _c("label", [_vm._v("Threshold Distance (meters)")]),
                _c(
                  "div",
                  { staticClass: "vertical-center" },
                  [
                    _c("CInput", {
                      attrs: {
                        type: "range",
                        disabled: !_vm.delivery_distances.fee_enabled,
                        value: _vm.delivery_distances.threshold_meter,
                        min: "1000",
                        max: "20000",
                        step: "500",
                        placeholder: "Enter threshold distance",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.delivery_distances,
                            "threshold_meter",
                            $event
                          )
                        },
                      },
                    }),
                    _c("span", { staticClass: "mb-3 ml-2" }, [
                      _vm._v(
                        _vm._s(_vm.delivery_distances.threshold_meter) + " m."
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("label", [_vm._v("Maximum Delivery Distance (meters)")]),
                _c(
                  "div",
                  { staticClass: "vertical-center flex-gap-2" },
                  [
                    _c("CSwitch", {
                      attrs: {
                        color: "success",
                        size: "sm",
                        checked: _vm.delivery_distances.max_distance_enabled,
                        labelOn: "On",
                        labelOff: "Off",
                      },
                      on: {
                        "update:checked": [
                          function ($event) {
                            return _vm.$set(
                              _vm.delivery_distances,
                              "max_distance_enabled",
                              $event
                            )
                          },
                          (val) =>
                            val
                              ? (_vm.delivery_distances.max_distance_meter = 1000)
                              : (_vm.delivery_distances.max_distance_meter = 0),
                        ],
                      },
                    }),
                    _c("CInput", {
                      staticClass: "mb-0",
                      attrs: {
                        type: "range",
                        disabled: !_vm.delivery_distances.max_distance_enabled,
                        value: _vm.delivery_distances.max_distance_meter,
                        min: "1000",
                        max: "20000",
                        step: "500",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.delivery_distances,
                            "max_distance_meter",
                            $event
                          )
                        },
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.delivery_distances.max_distance_meter) +
                          " m."
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c("CCardBody", { staticClass: "p-3" }, [
            _c("h5", { staticClass: "mb-3" }, [_vm._v("Delivery Zones")]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-4 mb-2" },
                [
                  _c("v-select", {
                    staticClass: "v-select-filter",
                    attrs: {
                      placeholder: "Country..",
                      value: _vm.country,
                      options: _vm.authCountries,
                      reduce: (c) => c.value,
                      searchable: false,
                    },
                    on: { input: _vm.countryFilter },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-lg mb-2" },
                [
                  _c(
                    "v-select",
                    {
                      staticClass: "v-select-filter",
                      attrs: {
                        placeholder: "Please select a region",
                        label: "zcCity",
                        options: _vm.allZipCodes,
                        reduce: (zc) => ({
                          id: zc.id,
                          zcZip: zc.zcZip,
                          zcCity: zc.zcCity,
                        }),
                        filter: _vm.filteredZipCodes,
                        loading: _vm.loading,
                      },
                      on: { input: _vm.selectingZipCodes },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function (zip_code) {
                            return [
                              _c("strong", [_vm._v(_vm._s(zip_code.zcZip))]),
                              _vm._v(" - "),
                              _c("span", [
                                _vm._v(_vm._s(zip_code.zcCity) + " "),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selected_zip_code,
                        callback: function ($$v) {
                          _vm.selected_zip_code = $$v
                        },
                        expression: "selected_zip_code",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v("Sorry, no matching options.")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "col-lg-12 mb-2 mb-lg-0" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "p-0 mr-2",
                        attrs: { size: "sm", color: "link" },
                        on: { click: _vm.getZipCodesNearBy },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-plus" } }),
                        _vm._v(
                          " Add delivery zones within " +
                            _vm._s(_vm.maxDistanceNearBy) +
                            " km "
                        ),
                      ],
                      1
                    ),
                    _c("CInput", {
                      staticClass: "m-0",
                      attrs: {
                        type: "range",
                        value: _vm.maxDistanceNearBy,
                        min: "1",
                        max: "20",
                        step: "1",
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.maxDistanceNearBy = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-12" },
                [
                  _c("CDataTable", {
                    staticClass: "table-sm table-zip-codes",
                    attrs: {
                      items: _vm.zipCodes,
                      fields: _vm.fields,
                      noItemsView: {
                        noResults: "No filtering results are available!",
                        noItems: "No linked ZIP codes found!",
                      },
                    },
                    on: { "row-clicked": (item) => (_vm.selected_row = item) },
                    scopedSlots: _vm._u([
                      {
                        key: "distance",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                item._classes === "table-warning"
                                  ? _c("CIcon", {
                                      attrs: {
                                        name: "cil-warning",
                                        size: "lg",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("FixedNumber")(item.distance)
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "country",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    content:
                                      _vm.$options.flagSet[item.country.flag],
                                    size: "lg",
                                  },
                                }),
                                _vm._v(" " + _vm._s(item.country.label) + " "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "zone",
                        fn: function ({ item }) {
                          return [
                            _c("td", { staticClass: "align-middle" }, [
                              _c("strong", [_vm._v(_vm._s(item.zcZip))]),
                              _c("small", { staticClass: "ml-1" }, [
                                _vm._v(_vm._s(item.zcCity)),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "delivery_charge",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c("CInput", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    lazy: false,
                                    value: item.pivot.delivery_charge,
                                    placeholder: "0.00",
                                    type: "number",
                                    step: "0.01",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                    onblur:
                                      "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                                    min: "0",
                                    max: _vm.maxDeliveryCharge,
                                    "was-validated": !item.pivot.valid,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        item.pivot,
                                        "delivery_charge",
                                        $event
                                      )
                                    },
                                    input: (value, event) =>
                                      _vm.validateDeliveryCharge(value, item),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-euro" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "minimum_order",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c("CInput", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    lazy: false,
                                    value: item.pivot.minimum_order,
                                    placeholder: "0.00",
                                    type: "number",
                                    step: "0.01",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                    onblur:
                                      "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                                    min: "0",
                                    max: _vm.maxMinimumOrder,
                                    "was-validated": !item.pivot.valid,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        item.pivot,
                                        "minimum_order",
                                        $event
                                      )
                                    },
                                    input: (value, event) =>
                                      _vm.validateMinimumOrder(value, item),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-euro" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "free_above",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c("CInput", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    lazy: false,
                                    value: item.pivot.free_above,
                                    placeholder: "0.00",
                                    type: "number",
                                    step: "0.01",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                    onblur:
                                      "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                                    min:
                                      item.pivot.minimum_order > 0 &&
                                      item.pivot.free_above > 0
                                        ? item.pivot.minimum_order
                                        : 0,
                                    max:
                                      item.pivot.delivery_charge > 0
                                        ? _vm.maxFreeAbove
                                        : 0,
                                    disabled: item.pivot.delivery_charge <= 0,
                                    "was-validated": !item.pivot.valid,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        item.pivot,
                                        "free_above",
                                        $event
                                      )
                                    },
                                    input: (value, event) =>
                                      _vm.validateFreeAbove(value, item),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-euro" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "status",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c("CSwitch", {
                                  attrs: {
                                    labelOn: "ACT",
                                    labelOff: "INA",
                                    color: "success",
                                    shape: "pill",
                                    disabled: !item.updatable,
                                    checked: item.enabled,
                                  },
                                  on: {
                                    "update:checked": [
                                      function ($event) {
                                        return _vm.$set(item, "enabled", $event)
                                      },
                                      () => _vm.updateAvailability(item),
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "actions",
                        fn: function ({ item, index }) {
                          return [
                            _c(
                              "td",
                              { staticClass: "align-middle" },
                              [
                                _c(
                                  "CButton",
                                  {
                                    staticClass: "ml-1",
                                    attrs: {
                                      size: "sm",
                                      color: "danger",
                                      disabled: !item.updatable,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-trash" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }